import React from 'react';
import { Tabs, Tab, Box, Select, MenuItem, useMediaQuery, type Theme, type SelectChangeEvent, Typography } from '@mui/material';
import { type IApprovalTab } from '../types';
import { CheckCircle, AccessTime, History } from '@mui/icons-material';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import '../styles/ApprovalTabs.scss';
import { useApprovalsContext } from './approvalContext';

interface ApprovalTabsProps {
  value: IApprovalTab["tab"]["value"];
  onChange: (newValue: IApprovalTab["tab"]) => void;
  tabs: IApprovalTab["tab"][];
}

const ApprovalTabs: React.FC<ApprovalTabsProps> = ({ value, onChange, tabs }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { config } = useApprovalsContext()!;

  const { approvalsCount } = config;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    const selectedTab = tabs.find((tab) => tab.value === newValue);
    selectedTab && onChange(selectedTab);
  };

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    const selectedTab = tabs.find((tab) => tab.value === newValue);
    selectedTab && onChange(selectedTab);
  };

  const renderCount = (value: IApprovalTab["tab"]["value"]) => {
    if(value === "myApprovals") return approvalsCount.myApprovalCount || 0
    if(value === "externalApprovals") return approvalsCount.externalApprovalCount || 0
    if(value === "approvalsHistory") return approvalsCount.historyCount || 0
    return null;

  }

  return (
    <Box className="approval-tabs-container">
      {isMobile ? (
        <Select
          value={value}
          onChange={handleDropdownChange}
          fullWidth
          className="approval-tabs-dropdown"
        >
          {tabs.map((tab, index) => (
            <MenuItem key={index} value={tab.value}>
              {tab.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Tabs
          value={value}
          onChange={handleChange}
          className="approval-tabs"
          sx={{
            display: 'flex',
            gap: 4,
          }}
          TabIndicatorProps={{
            sx: {
              display: 'none', // Hide the default indicator
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              className="approval-tab-item"
              label={
                <Box className="tab-content">
                  <Box className="tab-icon-count">
                    {tab.icon ?? null}
                    <Typography variant="h6" sx={{ lineHeight: 1 }}>{renderCount(tab.value)}</Typography>
                  </Box>
                  <Typography>{tab.label}</Typography>
                </Box>
              }
              value={tab.value}
            />
          ))}
        </Tabs>
      )}
    </Box>
  );
};

export default ApprovalTabs;