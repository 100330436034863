import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import ApprovalTabs from './ApprovalTabs';
import { fetchApprovals, fetchApprovalDetails } from './api';
import { type Approval, ApprovalDashboardProps, ApprovalDetails as ApprovalDetailsType, type ApprovalObject, IApprovalTab } from '../types';
import { ReactComponent as FileSvg } from '../images/files.svg';
import '../styles/ApprovalDashboard.scss';
import { useDispatch } from 'react-redux';
import { ApprovalContext } from './approvalContext';
import { tabs } from './utils';
import { ApprovalDetailsContainer } from 'modules/cbre-approval-details-widget';
import ApprovalList from 'modules/cbre-approvals-list/components/ApprovalList';

const ApprovalDashboard: React.FC<ApprovalDashboardProps> = ({ components = {}, config }) => {

  

  const [tabValue, setTabValue] = useState<IApprovalTab["tab"]>(tabs[0]);
  const [selectedApproval, setSelectedApproval] = useState<ApprovalObject | null>(null);
  const render = useRef({ isInitial: true }).current;
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const { approvalsCount, approvalsList } = config;

  useEffect(() => {
    // if(render.isInitial){
    //   render.isInitial = false;
    //   return;
    // }
    approvalsList.setQueryParams((prev:any) => ({ ...prev, search: '', pageNumber: 1 , order: approvalsList.initialQueryParams.order || 'asc' }));
    approvalsList.setApprovalMethod(tabValue.value)
    approvalsList.handlePendingChanges().then((config)=>{
      config.fetchApprovals(tabValue.value);
    });
    // fetchApprovals(tabValue, page).then(config.approvalsList.setApprovals);
    // approvalsCount.fetchApprovalsCount();
    setSelectedApproval(null);
  }, [tabValue, page]);

  const handleTabChange = (newValue: IApprovalTab["tab"]) => {
    setTabValue(newValue);
    setPage(0);
  };

  const handleApprovalClick = async (approval: ApprovalObject) => {
    // const details = await fetchApprovalDetails(approval.id);
    setSelectedApproval(approval);
  };

  const handleLoadMore = (count: number) => {
    // const moreApprovals = await fetchMoreApprovals();
    //     onLoadMore(moreApprovals);
    const { params: { pageSize, pageNumber }, totalPageCount } = config.approvalsList
    const newPageNumber = Math.ceil(count! / pageSize!);
    if(pageNumber == newPageNumber + 1) return;
    if(newPageNumber! + 1 > totalPageCount) return;
      config.approvalsList.setQueryParams((prev) => { 
        return {...prev, pageNumber: Number(newPageNumber || 0) + 1}
      }
    );
    config.approvalsList.handlePendingChanges().then((config) => {
      config.fetchApprovals(tabValue.value, true);
    });
    // setPage((prevPage) => prevPage + 1);
  };

  const contextProvider = { config, tabValue }

  return (
    <ApprovalContext.Provider value={contextProvider}>
      <Box className="approval-dashboard">
        <header className="dashboard-header">
          <Typography variant="h4">Approvals</Typography>
          <ApprovalTabs value={tabValue.value} onChange={handleTabChange} tabs={tabs} />
        </header>
        <main className="dashboard-content">
          <ApprovalList
            onApprovalClick={handleApprovalClick}
            onLoadMore={handleLoadMore}
            selectedApproval={selectedApproval}
            components={components}
            config={config}
          />

          <Box className="approval-details-section">
            {selectedApproval ? <ApprovalDetailsContainer approval={selectedApproval} /> : (
              <div className={`no-approvals d-flex justify-content-center align-items-center flex-column h-100`} >
                <FileSvg />
              </div>
            )}

          </Box>

        </main>
      </Box>
    </ApprovalContext.Provider>

  );
};

export default ApprovalDashboard;