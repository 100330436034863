import React from 'react';
import { Tab, Tabs, } from '@mui/material';
import { CheckCircle, PendingActions, Person } from '@mui/icons-material';
import '../styles/ApprovalChainHistoryContainer.scss';
import { ApprovalChain } from 'modules/cbre-approval-chain-widget';
import { Box } from '@mui/system';
import HistoryItem from 'modules/cbre-approval-history/components/historyItem';
import { ApprovalsHistory } from 'modules/cbre-approval-history';

interface ApprovalChainProps {
  approvals: number;
  totalApprovals: number;
  approvalChain: any
}

function TabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1,}}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ApprovalChainHistoryContainer: React.FC<ApprovalChainProps> = ({ approvals, totalApprovals }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="approvals-bar">
      <Tabs value={value} onChange={handleChange} aria-label="approval tabs" TabIndicatorProps={{
        sx: {
          display: 'none', // Hide the default indicator
        },
      }}>
        <Tab className='approval-chain-history-tab-item' label="Approval Chain" />
        <Tab className='approval-chain-history-tab-item' label="Approval History" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ApprovalChain approvals={approvals} totalApprovals={12} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ApprovalsHistory approvals={approvals} />
      </TabPanel>
    </div>
  );
};

export default ApprovalChainHistoryContainer;