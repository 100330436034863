import React, { useState } from 'react';
import { Drawer, Button, Select, MenuItem, TextField, FormControl, InputLabel, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ApprovalActions from './approvalActions';

type RequestDetailsViewDrawerProps = {
    open: boolean
    setIsOpen: (open: boolean) => void
};

const RequestDetailsViewDrawer: React.FC<RequestDetailsViewDrawerProps> = ({ open, setIsOpen }) => {

    const [comment, setComment] = useState('');

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setComment(event.target.value);
    };

    return (
        <div>
            <Drawer anchor="right" open={open} onClose={() => setIsOpen(false)} ModalProps={{ keepMounted: false }} style={{ zIndex: 1250 }}>
                <Box
                    sx={{ width: window.innerWidth - (window.innerWidth / 3), padding: 2, display: 'flex', flexDirection: 'column', height: '100%' }}
                    role="presentation"
                >
                    <div className='d-flex justify-content-end align-items-center'>
                        <ApprovalActions />
                        <IconButton onClick={() => { setIsOpen(false) }}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <TextField
                        label="Comments (Optional)"
                        multiline
                        rows={4}
                        value={comment}
                        onChange={handleCommentChange}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                    />
                    <Box sx={{ flexGrow: 1 }} />


                </Box>
            </Drawer>
        </div>
    );
};

export default RequestDetailsViewDrawer;