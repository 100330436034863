import React from 'react';
import HistoryItem from './historyItem';
import '../styles/ApprovalsBar.scss';

interface IhistoryItem {
  historyItems: Array<{
    status: string;
    date: string;
    author: string;
    role: string;
    email: string;
    comments?: string;
  }>;
}

interface ApprovalHistoryProps {
    approvals?: number;
    totalApprovals?: number;
  }

const ApprovalsHistory: React.FC<ApprovalHistoryProps> = ({ approvals }) => {
  const historyItems: IhistoryItem[] = []
  return (
    <div className="approvals-bar">
      <div className="history-wrap">
        {historyItems.length !== 0 && historyItems.map((item, index) => (
          <HistoryItem status={''} date={''} author={''} roleInfo={''} email={''} key={index} {...item} />
        ))}
      </div>
    </div>
  );
};

export default ApprovalsHistory;