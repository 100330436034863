import { Typography, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useApprovalsContext } from 'modules/cbre-approvals-widget/components/approvalContext';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom'
import { type ApprovalDetailDataObject } from '../types';

interface ApprovalDetailsProps {
    mode: 'mini' | 'large';
    approvalDetails: ApprovalDetailDataObject["data"];
}

const ApprovalDetailsView: React.FC<ApprovalDetailsProps> = ({ mode, approvalDetails }) => {

    const handleAssetId = () => {

        // return <Link className='asset-id-text' onClick={() => {
        //     //    config?.approvalDetails?.assetIdHandler?.(additionalDetails)
        // }}></Link>
    }

    const handleAttachment = () => {
        return <Box className="attachments-list">
            <Typography variant="body2">Attachments</Typography>
            {/* {approval.attachments.map((attachment: any, index: any) => (
                            <div className='d-flex align-items-center justify-content-between' key={index}>
                                <Typography>{attachment}</Typography>
                                <IconButton onClick={() => { console.log("") }}>
                                    <FileDownloadIcon />
                                </IconButton>
                            </div>
                        ))} */}
        </Box>
    }

    return (<>{approvalDetails.sectionDetails.map((item) => {
        return <Box className="details-section" key={item.sectionName}>
            <Typography variant="h6">{item.sectionName}</Typography>
            {
                item.additionalFields.map((detail) => {
                    return <Grid container className="detail-item" key={detail.fieldName}>
                        <Grid xs={4}>
                            <Typography variant="body2">{detail.displayName}</Typography>
                        </Grid>
                        <Grid xs={6}>
                            <Typography>{detail.value}</Typography>
                        </Grid>
                    </Grid>
                }
                )
            }
        </Box>
    })}</>)


};

export default ApprovalDetailsView;