import React, { useContext, useEffect, useRef } from 'react';
import { Typography, Box, Button, Chip, Grid, IconButton, Portal, Backdrop, CircularProgress } from '@mui/material';
import { type ApprovalContextProps, ApprovalDetails as ApprovalDetailsType, type ApprovalObject, type ReturnUseApprovalsProps } from 'modules/cbre-approvals-widget/types';
import { Divider } from '@mui/material';
import '../styles/ApprovalDetails.scss';
import ApprovalActions from './approvalActions';
import ApprovalChainHistoryContainer from 'modules/cbre-approvals-widget/components/ApprovalChainHistoryContainer';
import { useApprovalsContext } from 'modules/cbre-approvals-widget/components/approvalContext';
import RequestDetailsViewDrawer from './requestDetails';
import ApprovalDetailsView from './ApprovalDetails';


interface ApprovalDetailsContainerProps {
    approval: ApprovalObject;
}

const ApprovalDetailsContainer: React.FC<ApprovalDetailsContainerProps> = ({ approval }) => {

    const { config } = useApprovalsContext() as ApprovalContextProps;

    const container = useRef(null)

    const { baseUrl, approvalDetails } = config;

    const { loading, approvalDetail: approvalInfo } = approvalDetails;

    const [requestDetailsOpen, setRequestDetailsOpen] = React.useState(false);

    useEffect(() => {
        approvalDetails.getApprovalDetails(approval.approvalTaskId, approval.approvalFlow?.approvalFlowId)
    }, [approval.approvalTaskId])

    const details = approval.approvalFlow.sourceDetails
    const additionalDetails = details?.description ? details.additionalFields?.reduce((prev: any, item) => {
        prev[item.fieldName] = item;
        return prev
    }, {}) : {};

    const renderApprovalDetails = () => {

        //
    }

    const renderHeaderActions = () => {

        const isContractType = approval.approvalFlowStatus?.type === "contract"

        return (
            <Box className="action-buttons">
                <Button
                    variant={"outlined"}
                    onClick={() => { setRequestDetailsOpen(true) }}
                    sx={{
                        color: 'var(--primary-green-primary-main, #003f2d)',
                        borderColor: 'var(--primary-green-primary-main, #003f2d)',
                        '&:hover': {
                            backgroundColor: 'rgba(0, 63, 45, 0.05)',
                            margin: '0 16px',
                        },
                    }}
                >
                    {isContractType ? 'See contract details' : 'See request details'}
                </Button>
                <React.Fragment >
                    <Divider
                        orientation="vertical"
                        flexItem
                        sx={{
                            backgroundColor: 'rgba(0, 63, 45, 0.15)',
                            margin: '0 16px',
                        }}
                    />
                    <ApprovalActions />

                </React.Fragment>
            </Box>
        );
    }


    return (
        <Box className="approval-details" ref={container}>
            {
                approvalInfo && <>
                    <Box className="approval-detail-header">
                        <Box className="d-flex align-items-baseline" sx={{ gap: 2 }}>
                            <Typography variant="h5">{approvalInfo.heading}</Typography>
                            {additionalDetails.wr_number?.value && <Typography variant="subtitle1">SR# {additionalDetails.wr_number?.value}</Typography>}
                            {additionalDetails.c_compid?.value && <ul><li><Typography variant="subtitle1">{additionalDetails.c_compid?.value}</Typography></li></ul>}

                        </Box>

                        <Box className="action-buttons">
                            {renderHeaderActions()}
                        </Box>
                    </Box>
                    <Grid container gap={2}>
                        <Grid sm={7.5}>
                            <ApprovalDetailsView approvalDetails={approvalInfo} mode="mini" />
                        </Grid>
                        <Grid sm={4}><ApprovalChainHistoryContainer approvalChain={approval} approvals={12} totalApprovals={34} /> </Grid>
                    </Grid>
                </>
            }

            <RequestDetailsViewDrawer open={requestDetailsOpen} setIsOpen={setRequestDetailsOpen} />
            <Portal container={container.current}>
                <Backdrop open={loading} className="main-backdrop-custom">
                    <CircularProgress className="main-loader-custom" />
                </Backdrop>
            </Portal>
        </Box>
    );
};

export default ApprovalDetailsContainer;