import React, { useEffect, useRef, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { FixedSizeList as List } from 'react-window';
import ApprovalListItem from './ApprovalListItem';
import '../styles/ApprovalList.scss';
import NoApprovals from './NoApprovals';
import { type ReturnUseApprovalsProps, type ApprovalCustomComponents, type ApprovalObject } from 'modules/cbre-approvals-widget/types';


interface ApprovalListProps {
    selectedApproval: ApprovalObject | null;
    onApprovalClick: (approval: ApprovalObject) => void;
    onLoadMore: (count:number) => void;
    components: ApprovalCustomComponents
    config: ReturnUseApprovalsProps
}

const ApprovalList: React.FC<ApprovalListProps> = ({
    onApprovalClick,
    onLoadMore,
    selectedApproval,
    components,
    config
}) => {
    const [searchTerm, setSearchTextForApi] = useState('');
    const [searchText, setSearchText] = useState('');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [height, setHeight] = useState(300);
    const listContainer = useRef<HTMLDivElement | null>(null);
    const { pageSize, approvals, loading  } = config.approvalsList;
    

    useEffect(() => {
        if (listContainer.current) {
            const height = window.innerHeight - listContainer.current.offsetTop - 20;
            if (height > 300) setHeight(height);
        }
    }, [listContainer.current, approvals.length]);

    const loadingSkeleton = () => {
        return (
            <Box className="p-2" style ={{height: 80, borderBottom: "1px solid #003F2D26"}} >
                <div className='d-flex justify-content-between w-100'>
                <Skeleton animation="wave"  width={"40%"} height={20} />
                <Skeleton animation="wave"  width={"30%"} height={20} />
                </div>                
                <Skeleton  width={"60%"} height={20} />
                <Skeleton  width={"80%"} height={20} />
            </Box>
        );
    }

    return (
        <Box className="approval-list-section">
            { <Box className="approval-list-header">
               {components.Action && <components.Action config={config}   />}
            </Box>}
            <div className="virtual-list-container" ref={listContainer}>
                {loading ? <div>
                    {Array.from({length: Math.round(Math.ceil(height / 100))}).map(()=> loadingSkeleton() )}
                </div> : approvals.length === 0 ? (
                    <NoApprovals height={height} />
                ) : (
                    <List
                        height={height}
                        itemCount={approvals.length}
                        itemSize={80}
                        width="100%"
                        onItemsRendered={({ visibleStopIndex }: any) => {
                            
                            if (visibleStopIndex === approvals.length - 1) {
                                console.log(visibleStopIndex, "visibleStopIndex")
                                onLoadMore(visibleStopIndex);
                            }
                        }}
                    >
                        {({ index, style }: any) => (
                            <ApprovalListItem
                                approval={approvals[index]}
                                onClick={() => onApprovalClick(approvals[index])}
                                style={style}
                                selectedApproval={selectedApproval}
                            />
                        )}
                    </List>
                )}
            </div>
        </Box>
    );
};

export default ApprovalList;
