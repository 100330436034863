import React, { useState } from 'react';
import axios from 'axios';
import service from 'util/apiService';
import { type UseApprovalDetailsReturnType, type UseApprovalsDetailsProps } from '../types';
import { type ApprovalDetailDataObject } from '../types';

const useApprovalDetails = ({ url }: UseApprovalsDetailsProps) : UseApprovalDetailsReturnType => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [approvalDetail, setApprovalDetail] = useState<ApprovalDetailDataObject["data"] | null>(null);

    const approve = async (approvalId: string, payload:any) => {
        setError(null);
        try {
            const response = service.put(`${url}/approvals/approved/${approvalId}`)
            // Handle success response
            return response
        } catch (err) {
            setError('Failed to get approval details');
            throw err;
            console.error(err);
        } 
    };

    const getApprovalDetails = async (taskId: string, flowId:string) => {
        setLoading(true);
        setError(null);
        try {
            const { data }: ApprovalDetailDataObject = await service.get(`${url}/approvals/${taskId}/${flowId}`);
            setApprovalDetail(data);
            return data

        } catch (err) {
            setError('Failed to approve');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const reject = async (approvalId: string, payload:any) => {
        setError(null);
        try {
            const response = await service.put(`${url}/approvals/rejected/${approvalId}`)
            // Handle success response
            console.log('Rejected:', response.data);
            return response;
        } catch (err) {
            setError('Failed to reject');
            console.error(err);
        }
    };

    return { approve, reject, getApprovalDetails, loading, error, approvalDetail };
};

export default useApprovalDetails;